<template>
  <v-container>
    <v-row>
      <v-col :cols="12">
        <base-card>
          <div slot="heading" class="title font-weight-light">
            {{ $t('performance.new_sync_title') }}
          </div>
          <div class="d-flex justify-space-around">
            <div>
              <p>{{ $t('performance.sync_start_date') }}</p>
              <datepicker v-model="syncFrom" :format="dateFormat" :inline="true" />
            </div>
            <div>
              <p>{{ $t('performance.sync_end_date') }}</p>
              <datepicker
                v-model="syncTo"
                :format="dateFormat"
                :disabled-dates="toDisabledDates"
                :inline="true"
              />
            </div>
          </div>
          <v-divider class="my-5" />
          <div class="d-flex justify-center align-center mt-3">
            <div class="mr-3">{{ $t('performance.execute_sync') }}</div>
            <v-btn color="teal" dark @click="submit">{{ $t('common.execute') }}</v-btn>
          </div>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import BaseCard from '../components/common/BaseCard.vue'
import Datepicker from 'vuejs-datepicker'

export default {
  components: {
    Datepicker,
    BaseCard,
  },

  data() {
    return {
      dateFormat: 'yyyy/MM/dd',
      syncFrom: new Date(),
      syncTo: new Date(),
    }
  },

  computed: {
    toDisabledDates: function () {
      return {
        to: this.syncFrom,
      }
    },
  },

  methods: {
    ...mapActions(['updatePerformance', 'updateUserPerformance']),

    submit() {
      const payload = {
        from: moment(this.syncFrom).format(),
        to: moment(this.syncTo).format(),
      }
      Promise.all([this.updatePerformance(payload), this.updateUserPerformance(payload)]).then(
        () => {
          this.$snotify.success(this.$t('common.run_job_successfully'))
          this.$router.push({
            name: 'menu',
          })
        }
      )
    },
  },
}
</script>
